import { ModelAPI, ApiFilter } from '@thinknimble/tn-models'
import { objectToCamelCase } from '@thinknimble/tn-utils'
import { apiErrorHandler } from '../api'

import AxiosClient from '../AxiosClient'

// NOTE: The AxiosClient is congfigured to include '/api' in the baseUrl
const COMPANIES_ENDPOINT = '/companies/'
const INVESTORS_SEARCH_ENDPOINT = '/companies/investors/search/'
const INVESTORS_SEARCH_GSHEETS_EXPORT_ENDPOINT = '/companies/investors/gsheets-export/'

export default class CompanyAPI extends ModelAPI {
  get client() {
    return AxiosClient
  }

  static ENDPOINT = COMPANIES_ENDPOINT

  static FILTERS_MAP = {
    ...CompanyAPI.FILTERS_MAP,
    isPortfolioCompany: ApiFilter.create({ key: 'is_portfolio_company' }),
    search: ApiFilter.create({ key: 'search' }),
    name: ApiFilter.create({ key: 'name' }),
    hasCompanyIndustry: ApiFilter.create({ key: 'has_company_industry' }),
    recordTypeId: ApiFilter.create({ key: 'record_type_id' }),
    hasAnyOfRecordTypeIds: ApiFilter.create({ key: 'has_any_of_record_type_ids' }),
    wasOnMidasList: ApiFilter.create({ key: 'was_on_midas_list' }),
    leadsRounds: ApiFilter.create({ key: 'leads_rounds' }),
    coinvestsWithFrc: ApiFilter.create({ key: 'coinvests_with_frc' }),
    investsInAnyOfIndustries: ApiFilter.create({ key: 'invests_in_any_of_industries' }),
    investsInAnyOfStages: ApiFilter.create({ key: 'invests_in_any_of_stages' }),
    ceo: ApiFilter.create({ key: 'ceo' }),
    asGoogleSpreadsheet: ApiFilter.create({ key: 'as_google_spreadsheet' }),
    exportSelectedInvestors: ApiFilter.create({ key: 'export_selected_investors' }),
    excludedCompanyIds: ApiFilter.create({ key: 'excluded_invested_in_company_ids' }),
  }

  get = (companyId) => {
    const url = `${COMPANIES_ENDPOINT}${companyId}/`
    return this.client
      .get(url)
      .then((response) => objectToCamelCase(response.data))
      .catch(apiErrorHandler({ apiName: 'CompanyAPI.get' }))
  }

  // modeled after ModelAPI.list
  // differences: different url, returns objects (not instances of Company class)
  searchInvestors = ({ filters = {}, pagination = {} }) => {
    const url = INVESTORS_SEARCH_ENDPOINT
    const filtersMap = this.constructor.FILTERS_MAP
    const options = {
      params: ApiFilter.buildParams(filtersMap, {
        ...filters,
        page: pagination.page,
        pageSize: pagination.size,
      }),
    }
    return this.client
      .get(url, options)
      .catch(apiErrorHandler({ apiName: 'CompanyAPI.searchInvestors' }))
  }

  createNotionOnePager = (companySfId) => {
    const url = COMPANIES_ENDPOINT + companySfId + '/notion-one-pager/'
    return this.client
      .post(url)
      .then((r) => objectToCamelCase(r.data))
      .catch(apiErrorHandler({ apiName: 'CompanyAPI.createNotionOnePager' }))
  }
}
