<template>
  <span v-if="gp">
    <a
      :href="gp.salesforceUrl"
      target="_blank"
      class="text-blue-600 dark:text-blue-500 hover:underline"
    >
      {{ gp.fullName }}
    </a>
    <br v-if="gp.email" />
    <a
      v-if="gp.email"
      :href="'mailto:' + gp.email"
      class="text-blue-600 dark:text-blue-500 hover:underline"
    >
      {{ gp.email }}
    </a>
    <br v-if="gp.linkedinUrl" />
    <a
      v-if="gp.linkedinUrl"
      :href="gp.linkedinUrl"
      target="_blank"
      class="text-blue-600 dark:text-blue-500 hover:underline"
    >
      LinkedIn
    </a>
  </span>
  <span v-else> -- </span>
</template>

<script>
export default {
  name: 'GPInfoSpan',
  props: {
    gp: {
      type: Object,
      required: false,
    },
  },
}
</script>
