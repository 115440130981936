<template>
  <Modal @close="handleClose">
    <h3><strong>GPs of Investments in Selected Industries</strong></h3>
    <br />
    <table>
      <thead>
        <tr>
          <th>GP</th>
          <th>Company</th>
          <th>Date</th>
          <th>Deal Stage</th>
          <th>Was Lead Investor</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dealInvestor, index) in dealInvestors" :key="index">
          <td>
            <GPInfoSpan :gp="dealInvestor.gp" />
          </td>
          <td>
            <a
              :href="dealInvestor.deal.companySalesforceUrl"
              target="_blank"
              class="text-blue-600 dark:text-blue-500 hover:underline"
            >
              {{ dealInvestor.deal.companyName }}
            </a>
          </td>
          <td>{{ dealInvestor.deal.formattedDealDate }}</td>
          <td>{{ dealInvestor.deal.dealStage }}</td>
          <td>
            <CheckIcon v-if="dealInvestor.leadOrSoleInvestor" class="h-5 w-5" />
            <NoSymbolIcon v-else class="h-5 w-5" />
          </td>
        </tr>
      </tbody>
    </table>
  </Modal>
</template>

<script>
import { CheckIcon, NoSymbolIcon } from '@heroicons/vue/24/solid'
import Modal from '@/components/Modal.vue'
import GPInfoSpan from './GPInfoSpan.vue'

export default {
  name: 'InvestmentsModal',
  components: {
    CheckIcon,
    GPInfoSpan,
    NoSymbolIcon,
    Modal,
  },
  props: {
    dealInvestors: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}
</style>
