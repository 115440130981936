<template>
  <div class="relative h-full w-full">
    <div class="flex justify-start mb-2">
      <button @click="selectAllRows" class="mr-2 px-4 py-2 bg-blue-600 text-white rounded">
        Select All
      </button>
      <button @click="deselectAllRows" class="px-4 py-2 bg-gray-600 text-white rounded">
        Deselect All
      </button>
    </div>
    <AgGridVue
      :rowData="rowData"
      :columnDefs="colDefs"
      class="ag-theme-quartz h-full"
      allowDragFromColumnsToolPanel
      domLayout="autoHeight"
      rowSelection="multiple"
      suppressRowClickSelection
      @selectionChanged="handleRowSelection"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { AgGridVue } from 'ag-grid-vue3'
import Company from '@/services/companies'
import TrackedEvent, { events } from '@/services/trackedEvents'
import { trackHeapEvent } from '@/services/metrics'

export default {
  name: 'InvestorSearchResultsTable',
  components: {
    AgGridVue,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    industries: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore()

    const showDealInvestors = (dealInvestors, hasMultipleIndustries, industryName = null) => {
      if (dealInvestors && dealInvestors.length > 0) {
        emit('show-investors', dealInvestors, hasMultipleIndustries, industryName)
      } else {
        alert('No deal investors available.')
      }
    }

    const showGPs = (dealInvestors) => {
      if (dealInvestors && dealInvestors.length > 0) {
        emit('show-gps', dealInvestors)
      } else {
        alert('No deal investors available.')
      }
    }

    const columns = [
      {
        field: 'investor',
        headerName: 'Investor',
        checkboxSelection: true,
        cellRenderer: (params) => {
          const a = document.createElement('a')
          a.innerText = params.value
          a.href = '#'
          a.className = 'text-blue-600 dark:text-blue-500 hover:underline investor-gps'
          a.style.cursor = 'pointer'
          a.addEventListener('click', (event) => {
            event.preventDefault()
            trackHeapEvent('Investor Search View GPs', {
              investor: params.value,
              userId: store.getters.user.id,
              userEmail: store.getters.user.email,
            })
            TrackedEvent.api.create(store.getters.user.id, events.INVESTOR_SEARCH_VIEW_GPS, {
              investor: params.data.investor,
            })
            showGPs(params.data.salesforceDealInvestors)
          })
          return a
        },
      },
      {
        field: 'investorHqLocation',
        headerName: 'HQ Location',
        cellRenderer: (params) => {
          const div = document.createElement('div')
          div.innerText = params.value
          div.className = 'text-xs'
          div.style.display = 'flex'
          div.style.alignItems = 'center'
          div.style.justifyContent = 'center'
          div.style.height = '100%'
          return div
        },
      },
      {
        field: Company.getAnnotatedInvestmentsInIndustriesFieldName(),
        headerName: 'Industries Investments',
        cellRenderer: (params) => {
          const a = document.createElement('a')
          a.innerText = params.value
          a.href = '#'
          a.className =
            'text-blue-600 dark:text-blue-500 hover:underline investor-industries-investments'
          a.style.cursor = 'pointer'
          a.addEventListener('click', (event) => {
            event.preventDefault()
            trackHeapEvent('Investor Search View Investments', {
              investor: params.value,
              userId: store.getters.user.id,
              userEmail: store.getters.user.email,
            })
            TrackedEvent.api.create(
              store.getters.user.id,
              events.INVESTOR_SEARCH_VIEW_INVESTMENTS,
              { investor: params.data.investor },
            )
            showDealInvestors(params.data.salesforceDealInvestors, true)
          })
          return a
        },
      },
      {
        field: Company.getAnnotatedAllInvestmentsFieldName(),
        headerName: 'Total Investments',
      },
    ]

    props.industries.forEach((i) => {
      const totalFieldName = Company.getAnnotatedAllInvestmentsFieldName()
      const fieldName = Company.getAnnotatedIndustryInvestmentsFieldName(i.id)

      const colDef = {
        field: fieldName,
        headerName: i.name,
        valueFormatter: (params) => {
          const percent = Math.round((params.data[fieldName] / params.data[totalFieldName]) * 100)
          return params.value + ` (${percent}%)`
        },
        cellRenderer: (params) => {
          const a = document.createElement('a')
          a.innerText = params.value
          a.href = '#'
          a.className =
            'text-blue-600 dark:text-blue-500 hover:underline investor-industry-investments'
          a.style.cursor = 'pointer'
          a.addEventListener('click', (event) => {
            event.preventDefault()
            trackHeapEvent('Investor Search View Investments By Industry', {
              investor: params.value,
              industry: i.name,
              userId: store.getters.user.id,
              userEmail: store.getters.user.email,
            })
            TrackedEvent.api.create(
              store.getters.user.id,
              events.INVESTOR_SEARCH_VIEW_INVESTMENTS,
              { investor: params.data.investor, industry: i.name },
            )
            showDealInvestors(
              params.data.salesforceDealInvestors.filter((di) =>
                di.deal.industry_names.includes(i.name),
              ),
              false,
              i.name,
            )
          })
          return a
        },
      }
      columns.push(colDef)
    })

    const rows = props.results.map((r) => {
      const inIndustriesFieldName = Company.getAnnotatedInvestmentsInIndustriesFieldName()
      const totalFieldName = Company.getAnnotatedAllInvestmentsFieldName()

      const getHqLocation = (r) => {
        if (r.hqCity && r.hqCountry) {
          return `${r.hqCity}, ${r.hqCountry}`
        } else if (r.hqCity) {
          return r.hqCity
        } else if (r.hqCountry) {
          return r.hqCountry
        }
        return '--'
      }

      const row = {
        investor: r.name,
        investorId: r.id.toUpperCase(),
        investorHqLocation: getHqLocation(r),
        [inIndustriesFieldName]: r[inIndustriesFieldName],
        [totalFieldName]: r[totalFieldName],
        salesforceDealInvestors: r.salesforceDealInvestors,
      }

      props.industries.forEach((i) => {
        const fieldName = Company.getAnnotatedIndustryInvestmentsFieldName(i.id)
        row[fieldName] = r[fieldName]
      })

      return row
    })

    const colDefs = ref(columns)
    const rowData = ref(rows)

    const gridApi = ref(null)

    const onGridReady = (params) => {
      gridApi.value = params.api
    }

    const selectAllRows = () => {
      if (gridApi.value) {
        gridApi.value.selectAll()
      }
    }

    const deselectAllRows = () => {
      if (gridApi.value) {
        gridApi.value.deselectAll()
      }
    }

    return {
      rowData,
      colDefs,
      onGridReady,
      selectAllRows,
      deselectAllRows,
    }
  },
  methods: {
    handleRowSelection(event) {
      const selectedIds = event.api.getSelectedRows().map((r) => r.investorId)
      trackHeapEvent('Investor Search Select Investors', {
        userId: this.$store.getters.user.id,
        userEmail: this.$store.getters.user.email,
      })
      this.$emit('select-rows', selectedIds)
    },
  },
}
</script>

<style src="ag-grid-community/styles/ag-grid.css"></style>
<style src="ag-grid-community/styles/ag-theme-quartz.css"></style>
